import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./i18n";
import App from "./App";
import { combineReducers, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { authReducer } from "./redux/reducers/authReducer";
import { helperReducer } from "./redux/reducers/helperReducer";
import history from "./history";

const rootReducer = combineReducers({
  profile: authReducer,
  helpers: helperReducer,
});

export const store = createStore(
  rootReducer,
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
