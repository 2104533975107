import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import "./modal.scss";
import ModalTileType from "./ModalTileType";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from "../../redux/actions";
import { getTiles } from "../../crud/showcase/showcase";
import { ReactComponent as LeftArrow } from "../../assets/icon/left-arrow.svg";
import classNames from "classnames";
import { ReactComponent as Back } from "../../assets/icon/arrow-left.svg";
import ReactPlayer from "react-player";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import AudioPlayer from "react-h5-audio-player";

const SwitcherText = styled("button", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }) => ({
  "&:hover": {
    transform: "scale(1.2)",
    fill: color,
    color,
  },
}));

const ModalTile = ({ space, back, matterport, hideMenuMobile }) => {
  const [modalTile, setModalTile] = useState(false);
  const [tileInfo, setTileInfo] = useState(null);
  const [typeTile, setTypeTile] = useState("");
  const [tileLinktype, setTileLinktype] = useState("");
  const [current, setCurrent] = useState(null);
  const [urlMedia, setUrlMedia] = useState(null);
  const [showBack, setShowBack] = useState(false);
  const [currMode, setCurrMode] = useState(null);
  const [sweep, setSweep] = useState([]);
  const dispatch = useDispatch();
  const color = useSelector((state) => state.profile.color);

  useEffect(() => {
    if (matterport !== null) {
      matterport.Model.getData()
        .then(function (model) {
          setSweep(model.sweeps);
        })
        .catch(function (error) {
          // Model data retrieval error.
        });
      matterport.Mode.current.subscribe(function (mode) {
        setCurrMode(mode);
      });
    }
  }, [matterport]);

  const changeModeAndGotToSweeps = async (position, rotation, mode, sweep) => {
    back();
    let getMode = mode;
    let getSweep = sweep;
    let getRotation = rotation;
    let getPosition = position;
    let { sweeps } = await matterport.Model.getData();
    let findSweep = sweeps.find((sweep) => sweep.position.x == getSweep);
    const transition = matterport.Sweep.Transition.FLY;
    const transitionMode = matterport.Mode.TransitionType.FLY;
    await matterport.Mode.moveTo(matterport?.Mode?.Mode?.DOLLHOUSE, {
      transition: matterport?.Mode?.TransitionType?.FLY,
    });
    await matterport.Sweep.moveTo(findSweep.sid, {
      rotation: getRotation,
      transition: transition,
    });
    await matterport.Mode.moveTo(getMode, {
      position: getPosition,
      rotation: getRotation,
    });
  };

  const goToSweep = async (position, rotation, mode, sweep) => {
    back();
    let getMode = mode;
    let getSweep = sweep;
    let getRotation = rotation;
    let getPosition = position;
    let { sweeps } = await matterport.Model.getData();
    let findSweep = sweeps.find((sweep) => sweep.position.x == getSweep);
    const transition = matterport.Sweep.Transition.FLY;
    const transitionMode = matterport.Mode.TransitionType.FLY;
    await matterport.Sweep.moveTo(findSweep.sid, {
      rotation: getRotation,
      transition: transition,
    });
    await matterport.Mode.moveTo(getMode, {
      position: getPosition,
      rotation: getRotation,
    });
  };

  const fetchTiles = () => {
    dispatch(showLoaderAction());
    getTiles(space?.id)
      .then((res) => {
        let data = res?.data;
        setTileInfo(data);
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(hideLoaderAction());
        }, 1000);
        setUrlMedia(null);
      });
  };
  useEffect(() => {
    fetchTiles();
  }, []);

  const getBg = (tile) => {
    console.log("tile:", tile);
    if (tile?.linktype === "image") {
      return `center / cover no-repeat url(${tile?.url})`;
    }
    if (tile?.backgroundimg) {
      return `center / cover no-repeat url(${tile?.backgroundimg})`;
    }
    if (tile?.payload?.backgroundColor) {
      return tile?.payload?.backgroundColor;
    }
  };

  const getBgHero = (tile) => {
    if (tile?.hero?.heroType === "image") {
      return `center / cover no-repeat url(${tile?.hero?.imageUrl})`;
    }
    if (tile?.hero?.backgroundImgUrl) {
      return `center / cover no-repeat url(${tile?.hero?.backgroundImgUrl})`;
    }
    if (tile?.hero?.payload?.backgroundColor) {
      return tile?.hero?.payload?.backgroundColor;
    }
  };

  const getHeightHero = (tile) => {
    if (tile?.tileSettings?.heroHeight) {
      return tile?.tileSettings?.heroHeight + "px";
    } else {
      return "150px";
    }
  };

  const getTemplate = () => {
    let getColum = space?.tileSettings?.layoutColumns;
    if (Number.isInteger(getColum)) {
      return `repeat(${getColum}, 1fr)`;
    } else {
      return;
    }
  };

  const getHeighForItem = () => {
    let getHeight = space?.tileSettings?.tileHeight;
    if (getHeight) {
      return getHeight + "px";
    } else {
      return "60px";
    }
  };

  const renderType = (type, data, isSimple = false) => {
    // let type = typeLink?.value
    switch (type) {
      case "text":
        return (
          <>
            {data?.title && (
              <p style={{ color: data?.color }} className="tile-section__title">
                {data?.title}
              </p>
            )}
          </>
        );
      case "image":
        return (
          <>
            <p style={{ color: data?.color }} className="tile-section__title">
              {data?.title}
            </p>
          </>
        );
      case "video":
        return (
          <>
            {data?.url && (
              <div className="tile-section tile-section--point">
                <ReactPlayer
                  url={data?.url}
                  light={true}
                  //controls={true}
                  width="100%"
                  height="100%"
                  className="react-player"
                />
                <p
                  style={{ color: data?.color }}
                  className="tile-section__title"
                >
                  {data?.title}
                </p>
              </div>
            )}
          </>
        );
      case "iframe":
        return (
          <>
            <p style={{ color: data?.color }} className="tile-section__title">
              {data?.title}
            </p>
          </>
        );
      case "music":
        return (
          <>
            {data?.url && (
              <div className="tile-section tile-section--audio tile-section--audio_small">
                <AudioPlayer
                  autoPlay={JSON.parse(data?.autoplay)}
                  src={data?.url}
                  onPlay={(e) => console.log("onPlay")}
                  onPlayError={(err) => {
                    debugger;
                  }}
                  // other props here
                  showSkipControls={false}
                  showJumpControls={false}
                  className="tile-modal__audio"
                  customProgressBarSection={[]}
                  customControlsSection={["MAIN_CONTROLS"]}
                />
                <p
                  className="tile-section__simple-header"
                  style={{ color: data?.color }}
                >
                  {data?.title}
                </p>
              </div>
            )}
          </>
        );
      case "newTab":
        return (
          <>
            <a
              href={data?.newTab?.url}
              target="_blank"
              className="tile-section tile-section--title"
            >
              <p style={{ color: data?.color }} className="tile-section__title">
                {data?.title}
              </p>
            </a>
          </>
        );
      case "mpFlyover":
        return (
          <>
            <p style={{ color: data?.color }} className="tile-section__title">
              {data?.title}
            </p>
          </>
        );
      case "mpDeeplink":
        return (
          <>
            <p style={{ color: data?.color }} className="tile-section__title">
              {data?.title}
            </p>
          </>
        );
      default:
        return <div></div>;
    }
  };

  const renderTypeHero = (type, data) => {
    // let type = typeLink?.value
    switch (type) {
      case "text":
        return (
          <>
            {
              <div
                style={{ color: data?.color }}
                dangerouslySetInnerHTML={{ __html: data?.text }}
                className="tile-section tile-section--text"
              ></div>
            }
          </>
        );
      case "video":
        return (
          <>
            {data?.url && (
              <div className={`tile-section`}>
                <ReactPlayer
                  url={data?.url}
                  light={true}
                  controls={true}
                  width="100%"
                  height="100%"
                  className="react-player"
                />
                <p
                  style={{ color: data?.color }}
                  className="tile-section__title"
                >
                  {data?.title}
                </p>
              </div>
            )}
          </>
        );
      case "iframe":
        return (
          <>
            {data?.iframe && (
              <iframe
                title="Iframe akrotonx"
                className="tile-section"
                referrerPolicy="no-referrer"
                src={data?.iframe}
                style={{ border: "none" }}
                scrolling="yes"
              />
            )}
            {data?.title && (
              <p style={{ color: data?.color }} className="tile-section__title">
                {data?.title}
              </p>
            )}
          </>
        );
      case "music":
        return (
          <>
            {data?.url && (
              <div className={`tile-section tile-section--audio`}>
                <AudioPlayer
                  autoPlay={JSON.parse(data?.autoplay)}
                  src={data?.url}
                  onPlay={(e) => console.log("onPlay")}
                  onPlayError={(err) => {
                    debugger;
                  }}
                  // other props here
                  showSkipControls={false}
                  showJumpControls={false}
                  className="tile-modal__audio"
                  customProgressBarSection={[
                    "CURRENT_TIME",
                    "PROGRESS_BAR",
                    "DURATION",
                  ]}
                  customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
                />
                {data?.title && (
                  <p
                    style={{ color: data?.color }}
                    className="tile-section__simple-header"
                  >
                    {data?.title}
                  </p>
                )}
              </div>
            )}
          </>
        );
      default:
        return (
          <div>
            {data?.title && (
              <p style={{ color: data?.color }} className="tile-section__title">
                {data?.title}
              </p>
            )}
          </div>
        );
    }
  };

  const handleFlip = () => setShowBack(!showBack);

  return (
    <>
      {modalTile && (
        <div>
          <ModalTileType
            close={() => {
              setCurrent(null);
              setModalTile(false);
              setTypeTile("");
              setTileLinktype("");
              setUrlMedia(null);
            }}
            current={current}
            typeTile={typeTile}
            typeLink={tileLinktype}
            urlMedia={urlMedia}
          />
        </div>
      )}
      <div className={`tile-modal ${showBack ? "tile-modal__flip" : ""}`}>
        <div className="tile-modal-inner">
          <div onClick={back} className="profile-back">
            <Back />
            <p>Back to Menu</p>
          </div>
          <div onClick={back} className="close-tile">
            <p>x</p>
          </div>
          <>
            <div className="tile-modal-front">
              <div
                style={{
                  background: getBgHero(tileInfo),
                  height: getHeightHero(tileInfo),
                }}
                className={classNames("lazyload", {
                  "tile-modal__hero": true,
                  "tile-modal__hero--hide": !space?.tileSettings?.showHero,
                })}
              >
                {renderTypeHero(tileInfo?.hero?.heroType, {
                  url: tileInfo?.hero?.videoUrl || tileInfo?.hero?.audioUrl,
                  title: tileInfo?.hero?.payload?.title,
                  color: tileInfo?.hero?.payload?.titleColor,
                  autoplay: tileInfo?.hero?.payload?.autoplay,
                  text: tileInfo?.hero?.payload?.text,
                  iframe: tileInfo?.hero?.payload?.url,
                })}
              </div>

              <div
                className="tile-modal__tile"
                style={{ gridTemplateColumns: getTemplate() }}
              >
                {tileInfo?.tiles?.map((tile, index) => {
                  return (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        let content = tile.linktype;
                        if (content === "music" || content === "newTab") {
                          return;
                        } else if (content === "mpDeeplink") {
                          hideMenuMobile();
                          let params = tile.payload.url.split("&");
                          goToSweep(
                            JSON.parse(params[1]),
                            JSON.parse(params[2]),
                            params[3],
                            params[4]
                          );
                          return;
                        } else if (content === "mpFlyover") {
                          hideMenuMobile();
                          let params = tile.payload.url.split("&");
                          changeModeAndGotToSweeps(
                            JSON.parse(params[1]),
                            JSON.parse(params[2]),
                            params[3],
                            params[4]
                          );
                          return;
                        }
                        setTileLinktype({
                          name: `${
                            tile?.linktype.charAt(0).toUpperCase() +
                            tile.linktype.slice(1)
                          }`,
                          value: tile.linktype,
                        });
                        setModalTile(true);
                        setCurrent(tile?.payload);
                        setUrlMedia(tile?.url);
                        setTypeTile("tile");
                      }}
                      style={{
                        background: getBg(tile),
                        height: getHeighForItem(),
                      }}
                      className="tile-modal__item_tile lazyload"
                    >
                      {renderType(
                        tile.linktype,
                        {
                          url: tile?.url,
                          title: tile?.payload?.title,
                          color: tile?.payload?.titleColor,
                          autoplay: tile?.payload?.autoplay,
                          newTab: {
                            url: tile?.payload?.url,
                          },
                        },
                        true
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="tile-modal-back">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </>
        </div>
        {space?.tileSettings?.help && (
          <div className="tile-modal__help">
            <SwitcherText
              className="tile-modal__switcher-text"
              onClick={handleFlip}
              color={color}
            >
              {showBack ? (
                <>
                  <LeftArrow style={{ width: "15px", height: "20px" }} />
                  Back to Tiles
                </>
              ) : (
                "Help"
              )}
            </SwitcherText>
          </div>
        )}
      </div>
    </>
  );
};

export default ModalTile;
